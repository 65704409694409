import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Sidebar from './components/Layout/Sidebar'

const Layout = ({ children }) => {
  // Remove automatic opening based on screen size
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setIsMobile(width < 1024)

      // Only set initial state on first load, not on every resize
      if (width >= 1024 && !localStorage.getItem('sidebarState')) {
        setIsSidebarOpen(true)
        localStorage.setItem('sidebarState', 'open')
      }
    }

    // Check if we have a saved state
    const savedState = localStorage.getItem('sidebarState')
    if (savedState) {
      setIsSidebarOpen(savedState === 'open')
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Save sidebar state when it changes
  const handleSidebarToggle = (newState) => {
    setIsSidebarOpen(newState)
    localStorage.setItem('sidebarState', newState ? 'open' : 'closed')
  }

  return (
    <div className='flex h-screen bg-[#0b0a45]'>
      <Sidebar
        isOpen={isSidebarOpen}
        setIsOpen={handleSidebarToggle}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isMobile={isMobile}
      />
      <motion.div
        className={`flex flex-col flex-grow ${
          isMobile ? '' : isCollapsed ? 'lg:ml-20' : 'lg:ml-60'
        }`}
        layout
        transition={{
          type: 'spring',
          stiffness: 350,
          damping: 30,
        }}
      >
        <motion.main
          className='flex-grow p-4 overflow-auto'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            ease: 'easeOut',
          }}
        >
          {children}
        </motion.main>
      </motion.div>
    </div>
  )
}

export default Layout
