import React from 'react'
import { useNavigate } from 'react-router-dom'

const TableFilters = ({
  filters,
  onFilterChange,
  onReset,
  entriesPerPage,
  onEntriesPerPageChange,
  onExport,
  data,
}) => {
  const navigate = useNavigate()

  const handleExportToExcel = () => {
    if (!data?.length) return

    const headers = [
      'ID',
      'Name',
      'Class',
      'Father Name',
      'Father Cell',
      'Mother Name',
    ]

    const csvContent = [
      headers.join(','),
      ...data.map((row, index) =>
        [
          index + 1,
          row.studentInfo.name,
          row.studentInfo.grade,
          row.fatherInfo.name,
          row.fatherInfo.cell,
          row.motherInfo.name,
        ].join(',')
      ),
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `enrolled-students-${
      new Date().toISOString().split('T')[0]
    }.csv`
    link.click()
    URL.revokeObjectURL(url)
  }

  return (
    <div className='bg-white/5 backdrop-blur-md rounded-lg p-4 mb-4 border border-white/10'>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3'>
        {/* Search by Name */}
        <div className='relative'>
          <input
            type='text'
            value={filters.searchName}
            onChange={(e) => onFilterChange('searchName', e.target.value)}
            placeholder='Search by name...'
            className='w-full bg-white/10 text-white border border-white/20 px-3 py-2 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/30 placeholder-white/50'
          />
        </div>

        {/* Search by Father's Name */}
        <div className='relative'>
          <input
            type='text'
            value={filters.searchFatherName}
            onChange={(e) => onFilterChange('searchFatherName', e.target.value)}
            placeholder="Search by father's name..."
            className='w-full bg-white/10 text-white border border-white/20 px-3 py-2 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/30 placeholder-white/50'
          />
        </div>

        {/* Search by Father's Cell */}
        <div className='relative'>
          <input
            type='text'
            value={filters.searchFatherCell}
            onChange={(e) => onFilterChange('searchFatherCell', e.target.value)}
            placeholder="Search by father's cell..."
            className='w-full bg-white/10 text-white border border-white/20 px-3 py-2 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/30 placeholder-white/50'
          />
        </div>

        {/* Class Filter */}
        <select
          value={filters.class}
          onChange={(e) => onFilterChange('class', e.target.value)}
          className='w-full bg-white/10 text-white border border-white/20 px-3 py-2 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/30'
        >
          <option value='Choose'>All Classes</option>
          {[
            'PG',
            'Nursery',
            'KG',
            ...Array.from({ length: 7 }, (_, i) => `Grade ${i + 1}`),
          ].map((grade) => (
            <option key={grade} value={grade} className='bg-gray-800'>
              {grade}
            </option>
          ))}
        </select>

        {/* Status Filter */}
        <select
          value={filters.status}
          onChange={(e) => onFilterChange('status', e.target.value)}
          className='w-full bg-white/10 text-white border border-white/20 px-3 py-2 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/30'
        >
          <option value='All' className='bg-gray-800'>
            All Status
          </option>
          <option value='Approved' className='bg-gray-800'>
            Approved
          </option>
          <option value='Pending' className='bg-gray-800'>
            Pending
          </option>
        </select>
      </div>

      {/* Second row for entries per page and action buttons */}
      <div className='mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3'>
        {/* Entries per page */}
        <select
          value={entriesPerPage}
          onChange={(e) => onEntriesPerPageChange(Number(e.target.value))}
          className='w-full bg-white/10 text-white border border-white/20 px-3 py-2 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/30'
        >
          {[10, 25, 50].map((value) => (
            <option key={value} value={value} className='bg-gray-800'>
              {value} per page
            </option>
          ))}
        </select>

        {/* Spacer for grid alignment */}
        <div className='hidden lg:block col-span-3'></div>

        {/* Action buttons */}
        <div className='flex gap-2 lg:justify-end'>
          <button
            onClick={onReset}
            className='px-4 py-2 text-sm bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors'
          >
            Reset Filters
          </button>
          <button
            onClick={() => navigate('recent')}
            className='px-4 py-2 text-sm bg-gradient-to-r from-orange-600 to-orange-700 text-white rounded-lg hover:from-orange-700 hover:to-orange-800 transition-all'
          >
            Recycle Bin
          </button>
          <button
            onClick={handleExportToExcel}
            disabled={!data?.length}
            className='px-4 py-2 text-sm bg-gradient-to-r from-emerald-600 to-emerald-700 text-white rounded-lg hover:from-emerald-700 hover:to-emerald-800 transition-all disabled:opacity-50 disabled:cursor-not-allowed'
          >
            Export
          </button>
        </div>
      </div>
    </div>
  )
}

export default TableFilters
