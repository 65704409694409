import {
  BarChart3,
  BookOpen,
  GraduationCap,
  LayoutGrid,
  LogOut,
  Menu,
  MessageSquare,
  PanelLeftClose,
  PanelLeftOpen,
  Shield,
  User,
  Users,
  UserSquare2,
} from 'lucide-react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../redux/userSlice'

const menuItems = [
  { name: 'Dashboard', icon: LayoutGrid, path: '/dashboard' },
  { name: 'Students', icon: Users, path: '/students' },
  { name: 'Class', icon: GraduationCap, path: '/class' },
  { name: 'Teachers', icon: UserSquare2, path: '/teachers' },
  { name: 'Subjects', icon: BookOpen, path: '/subjects' },
  { name: 'Publish', icon: Shield, path: '/publish' },
  { name: 'Reports', icon: BarChart3, path: '/reports' },
  { name: 'Users', icon: User, path: '/users' },
  { name: 'Chat', icon: MessageSquare, path: '/chat' },
]

const Sidebar = ({
  isOpen,
  setIsOpen,
  isCollapsed,
  setIsCollapsed,
  isMobile,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  const toggleSidebar = () => {
    if (isMobile) {
      setIsOpen(!isOpen)
    } else {
      setIsCollapsed(!isCollapsed)
    }
  }

  return (
    <>
      {/* Mobile Menu Button */}
      {isMobile && (
        <button
          onClick={toggleSidebar}
          className='fixed top-4 left-4 z-50 p-2 bg-[#0b0a45] rounded-lg lg:hidden'
        >
          <Menu className='w-6 h-6 text-white' />
        </button>
      )}

      {/* Backdrop for mobile */}
      {isMobile && isOpen && (
        <div
          className='fixed inset-0 bg-black/50 z-30 lg:hidden'
          onClick={() => setIsOpen(false)}
        />
      )}

      <aside
        className={`fixed inset-y-0 left-0 z-40 bg-[#0b0a45] text-white transition-all duration-300 
          ${isCollapsed ? 'w-20' : 'w-60'} 
          ${
            isMobile
              ? isOpen
                ? 'translate-x-0'
                : '-translate-x-full'
              : 'translate-x-0'
          }
          flex flex-col`}
      >
        {/* Logo Section */}
        <div className='flex flex-col items-center pt-6 pb-4'>
          <div
            className={`transition-all duration-300 ${
              isCollapsed ? 'w-12' : 'w-20'
            }`}
          >
            <img
              src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logo-white.png?raw=true'
              alt='Logo'
              className='w-full h-full object-contain'
            />
          </div>

          {!isCollapsed && (
            <>
              <h1 className='mt-3 text-xl font-bold text-white'>
                The Orion School
              </h1>
              <p className='text-sm text-green-400'>Management System</p>
            </>
          )}
        </div>

        {/* Navigation */}
        <nav className='flex-1 px-3 py-4 space-y-1'>
          {menuItems.map((item) => {
            const Icon = item.icon
            const isActive = location.pathname === item.path

            return (
              <Link
                key={item.name}
                to={item.path}
                onClick={() => isMobile && setIsOpen(false)}
                className={`flex items-center px-3 py-2.5 rounded-lg transition-all duration-200
                  group relative cursor-pointer
                  ${
                    isActive
                      ? 'bg-green-600 text-white'
                      : 'text-gray-300 hover:bg-white/10'
                  }`}
              >
                <Icon
                  className={`w-5 h-5 ${isCollapsed ? 'mx-auto' : 'mr-3'}`}
                />
                {!isCollapsed && <span className='text-base'>{item.name}</span>}
                {isCollapsed && (
                  <div className='absolute left-full ml-6 px-2 py-1 bg-[#0b0a45] text-white text-sm rounded-md opacity-0 group-hover:opacity-100 pointer-events-none transition-opacity whitespace-nowrap z-50'>
                    {item.name}
                  </div>
                )}
              </Link>
            )
          })}
        </nav>

        {/* Footer with toggle and logout */}
        <div className='p-3 border-t border-white/10 space-y-2'>
          {!isMobile && (
            <button
              onClick={toggleSidebar}
              className={`flex items-center w-full px-3 py-2 rounded-lg transition-all duration-200
                text-gray-300 hover:bg-white/10`}
            >
              {isCollapsed ? (
                <PanelLeftOpen className='w-5 h-5 mx-auto' />
              ) : (
                <>
                  <PanelLeftClose className='w-5 h-5 mr-3' />
                  <span>Collapse Sidebar</span>
                </>
              )}
            </button>
          )}

          <button
            onClick={handleLogout}
            className='flex items-center justify-center w-full px-3 py-2 bg-red-600 hover:bg-red-700 rounded-lg transition-colors gap-2'
          >
            <LogOut className={`w-5 h-5 ${isCollapsed ? 'mx-auto' : ''}`} />
            {!isCollapsed && <span>Logout</span>}
          </button>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
