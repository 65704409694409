import { ArrowLeft, Edit, Printer, Trash } from 'lucide-react'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'

const ViewEnrolled = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  const [studentInfo, setStudentInfo] = useState({})
  const [fatherInfo, setFatherInfo] = useState({})
  const [motherInfo, setMotherInfo] = useState({})
  const [familyInfo, setFamilyInfo] = useState({})
  const [extracurricular, setExtracurricular] = useState({})
  const [medicalHistory, setMedicalHistory] = useState({})

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB')
  }

  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      await axiosInstance.put(`/student/delete-by-id/${params.id}`)
      navigate('/students/enrolled')
    } catch (err) {
      console.log(err)
    }
  }

  const { data } = useQuery(
    'specific-view-student',
    async () => {
      const res = await axiosInstance.get(`/admission/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setStudentInfo(data.studentInfo || {})
        setFatherInfo(data.fatherInfo || {})
        setMotherInfo(data.motherInfo || {})
        setFamilyInfo(data.familyInfo || {})
        setExtracurricular(data.extracurricular || { sports: [], clubs: [] })
        setMedicalHistory(data.medicalHistory || {})
        setIsLoading(false)
      },
    }
  )

  const InfoField = ({ label, value, fullWidth }) => (
    <div className={`mb-2 ${fullWidth ? 'w-full' : 'w-full md:w-1/2'} px-2`}>
      <div className='flex items-center'>
        <span className='text-gray-600 text-sm font-medium w-32'>{label}:</span>
        <div className='flex-1 border-b border-gray-300 py-1 text-gray-900 text-sm'>
          {value || 'N/A'}
        </div>
      </div>
    </div>
  )

  const Section = ({ title, children }) => (
    <div className='mb-4'>
      <h2 className='text-sm font-bold mb-2 px-2 py-1 bg-gray-100 text-gray-800'>
        {title}
      </h2>
      <div className='flex flex-wrap'>{children}</div>
    </div>
  )

  if (isLoading) {
    return (
      <Layout>
        <Topbar title='Student Profile' />
        <div className='flex items-center justify-center min-h-screen'>
          <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500'></div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <style>
        {`
          @page {
            size: A4;
            margin: 20mm;
          }
          @media print {
            html, body {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
            }
            body * {
              visibility: hidden;
            }
            .no-print, nav, header, aside {
              display: none !important;
            }
            #printable-content, #printable-content * {
              visibility: visible;
            }
            #printable-content {
              position: static;
              width: 100%;
              margin: 0;
              padding: 0;
            }
            .print-section {
              break-inside: avoid;
            }
            .section-wrapper {
              break-inside: avoid;
              page-break-inside: avoid;
            }
            #layout-wrapper {
              margin: 0 !important;
              padding: 0 !important;
            }
          }
        `}
      </style>

      <Topbar title='Student Profile' className='no-print' />

      <div className='p-4'>
        <div className='max-w-[210mm] mx-auto bg-white shadow-md'>
          <div id='printable-content' className='p-6'>
            {/* Header */}
            <div className='text-center mb-4 section-wrapper'>
              <img
                src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logobw.png?raw=true'
                alt='School Logo'
                className='w-16 mx-auto mb-2'
              />
              <h1 className='text-2xl font-bold text-gray-800 mb-1'>
                The Orion School
              </h1>
              <div className='text-xs text-gray-600 space-y-0.5'>
                <p>www.theorionschool.com</p>
                <p>+92 307 7879977</p>
                <p>help@orionedu.net</p>
              </div>
            </div>

            <div className='section-wrapper'>
              <Section title='Student Information'>
                <InfoField label='Name' value={studentInfo.name} />
                <InfoField label='Admission #' value={data?.admissionNumber} />
                <InfoField label='Grade' value={studentInfo.grade} />
                <InfoField label='Gender' value={studentInfo.gender} />
                <InfoField
                  label='Date of Birth'
                  value={studentInfo.dob ? formatDate(studentInfo.dob) : ''}
                />
                <InfoField
                  label='Previous School'
                  value={studentInfo.prevSchool}
                />
              </Section>
            </div>

            <div className='section-wrapper'>
              <Section title="Father's Information">
                <InfoField label='Name' value={fatherInfo.name} />
                <InfoField label='CNIC' value={fatherInfo.cnic} />
                <InfoField label='Cell' value={fatherInfo.cell} />
                <InfoField label='Email' value={fatherInfo.email} />
                <InfoField label='Occupation' value={fatherInfo.occupation} />
                <InfoField label='Company' value={fatherInfo.company} />
              </Section>
            </div>

            <div className='section-wrapper'>
              <Section title="Mother's Information">
                <InfoField label='Name' value={motherInfo.name} />
                <InfoField label='Cell' value={motherInfo.cell} />
                <InfoField label='Education' value={motherInfo.education} />
                <InfoField label='Occupation' value={motherInfo.occupation} />
                <InfoField
                  label='Working Status'
                  value={motherInfo.isWorking ? 'Working' : 'Not Working'}
                />
                <InfoField label='Company' value={motherInfo.company} />
              </Section>
            </div>

            <div className='section-wrapper'>
              <Section title='Other Information'>
                <InfoField
                  label='Address'
                  value={familyInfo.address}
                  fullWidth
                />
                <InfoField
                  label='First Language'
                  value={familyInfo.firstLanguage}
                />
                <InfoField
                  label='Other Languages'
                  value={familyInfo.otherLanguages?.join(', ')}
                />
              </Section>
            </div>

            <div className='section-wrapper'>
              <Section title='Siblings Information'>
                {familyInfo.siblings && familyInfo.siblings.length > 0 ? (
                  familyInfo.siblings.map((sibling, index) => (
                    <InfoField
                      key={index}
                      label={`Sibling ${index + 1}`}
                      value={`${sibling.name || 'N/A'} - ${
                        sibling.grade || 'N/A'
                      } - ${sibling.section || 'N/A'}`}
                      fullWidth
                    />
                  ))
                ) : (
                  <InfoField
                    label='Siblings'
                    value='No siblings registered'
                    fullWidth
                  />
                )}
              </Section>
            </div>

            <div className='section-wrapper'>
              <Section title='Extracurricular Activities'>
                <InfoField
                  label='Sports'
                  value={extracurricular.sports?.join(', ')}
                  fullWidth
                />
                <InfoField
                  label='Clubs'
                  value={extracurricular.clubs?.join(', ')}
                  fullWidth
                />
              </Section>
            </div>

            {Object.keys(medicalHistory).length > 0 && (
              <div className='section-wrapper'>
                <Section title='Medical History'>
                  {Object.entries(medicalHistory).map(([key, value]) => (
                    <InfoField
                      key={key}
                      label={key.replace(/([A-Z])/g, ' $1').trim()}
                      value={
                        typeof value === 'boolean'
                          ? value
                            ? 'Yes'
                            : 'No'
                          : value
                      }
                      fullWidth
                    />
                  ))}
                </Section>
              </div>
            )}

            {/* Footer */}
            <div className='text-center text-xs text-gray-600 mt-4 pt-2 border-t'>
              www.theorionschool.com | +92 307 7879977 | help@orionedu.net
            </div>
          </div>

          {/* Action Buttons */}
          <div className='flex justify-center gap-3 p-4 bg-gray-50 no-print'>
            <button
              onClick={() => navigate(-1)}
              className='flex items-center px-3 py-1.5 bg-gray-600 text-white rounded hover:bg-gray-700 text-sm'
            >
              <ArrowLeft className='w-4 h-4 mr-1' />
              Back
            </button>
            <Link
              to={`/students/enrolled/edit/${params.id}`}
              className='flex items-center px-3 py-1.5 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm'
            >
              <Edit className='w-4 h-4 mr-1' />
              Edit
            </Link>
            <button
              onClick={handleDelete}
              className='flex items-center px-3 py-1.5 bg-red-600 text-white rounded hover:bg-red-700 text-sm'
            >
              <Trash className='w-4 h-4 mr-1' />
              Delete
            </button>
            <button
              onClick={() => window.print()}
              className='flex items-center px-3 py-1.5 bg-green-600 text-white rounded hover:bg-green-700 text-sm'
            >
              <Printer className='w-4 h-4 mr-1' />
              Print
            </button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ViewEnrolled
