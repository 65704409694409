import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../../config'
import TableFilters from './TableFilters'

const INITIAL_FILTERS = {
  searchName: '',
  searchFatherName: '',
  searchFatherCell: '',
  class: 'Choose',
  status: 'All',
}

const EnrolledDataTable = () => {
  const navigate = useNavigate()
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [currentPage, setCurrentPage] = useState(1)
  const [entriesPerPage, setEntriesPerPage] = useState(10)

  // Fetch data
  const { data, status } = useQuery(
    ['all-students-enrolled'],
    async () => {
      const res = await axiosInstance.get(`/admission/enrolled`)
      return res.data
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  )

  // Format date helper
  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }, [])

  // Filter and pagination logic
  const { filteredData, paginatedData, totalPages, totalRows } = useMemo(() => {
    if (!data) {
      return {
        filteredData: [],
        paginatedData: [],
        totalPages: 0,
        totalRows: 0,
      }
    }

    // Apply all filters
    const filtered = data.filter((item) => {
      const nameMatch =
        !filters.searchName ||
        item.studentInfo.name
          .toLowerCase()
          .includes(filters.searchName.toLowerCase())

      const fatherNameMatch =
        !filters.searchFatherName ||
        item.fatherInfo.name
          .toLowerCase()
          .includes(filters.searchFatherName.toLowerCase())

      const fatherCellMatch =
        !filters.searchFatherCell ||
        item.fatherInfo.cell.includes(filters.searchFatherCell)

      const classMatch =
        filters.class === 'Choose' || item.studentInfo.grade === filters.class

      const statusMatch =
        filters.status === 'All' || item.status === filters.status

      return (
        nameMatch &&
        fatherNameMatch &&
        fatherCellMatch &&
        classMatch &&
        statusMatch
      )
    })

    // Calculate pagination
    const total = filtered.length
    const pages = Math.ceil(total / entriesPerPage)
    const startIndex = (currentPage - 1) * entriesPerPage
    const paginated = filtered.slice(startIndex, startIndex + entriesPerPage)

    return {
      filteredData: filtered,
      paginatedData: paginated,
      totalPages: pages,
      totalRows: total,
    }
  }, [data, filters, currentPage, entriesPerPage])

  // Event handlers
  const handleFilterChange = useCallback((key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
    setCurrentPage(1)
  }, [])

  const handleReset = useCallback(() => {
    setFilters(INITIAL_FILTERS)
    setCurrentPage(1)
  }, [])

  const handleEntriesPerPageChange = useCallback((value) => {
    setEntriesPerPage(value)
    setCurrentPage(1)
  }, [])

  // Loading state
  if (status === 'loading') {
    return (
      <div className='flex justify-center items-center h-64'>
        <div className='relative w-20 h-20'>
          <div className='absolute top-0 left-0 w-full h-full border-4 border-indigo-200 rounded-full animate-pulse'></div>
          <div className='absolute top-0 left-0 w-full h-full border-t-4 border-indigo-600 rounded-full animate-spin'></div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full max-w-[95vw] mx-auto my-4'>
      <TableFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        onReset={handleReset}
        entriesPerPage={entriesPerPage}
        onEntriesPerPageChange={handleEntriesPerPageChange}
        data={filteredData} // Pass filtered data for export
      />

      <div className='bg-white/5 backdrop-blur-md rounded-lg overflow-hidden border border-white/10'>
        <div className='overflow-x-auto'>
          <table className='min-w-full divide-y divide-white/10'>
            <thead>
              <tr className='bg-white/5'>
                {[
                  'ID',
                  'Apply Date',
                  'Name',
                  "Father's Name",
                  "Father's Cell",
                  "Mother's Name",
                  'Class',
                  'Status',
                  'Actions',
                ].map((header) => (
                  <th
                    key={header}
                    className='px-4 py-3 text-left text-xs font-medium text-white/70 uppercase tracking-wider'
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-white/10'>
              {paginatedData.map((row, index) => (
                <tr
                  key={row._id}
                  onClick={() => navigate(`view/${row._id}`)}
                  className='hover:bg-white/5 transition-colors cursor-pointer'
                >
                  <td className='px-4 py-3 text-sm text-white/70'>
                    {(currentPage - 1) * entriesPerPage + index + 1}
                  </td>
                  <td className='px-4 py-3 text-sm text-white/70'>
                    {formatDate(row.createdAt)}
                  </td>
                  <td className='px-4 py-3 text-sm text-white'>
                    {row.studentInfo.name}
                  </td>
                  <td className='px-4 py-3 text-sm text-white/70'>
                    {row.fatherInfo.name}
                  </td>
                  <td className='px-4 py-3 text-sm text-white/70'>
                    {row.fatherInfo.cell}
                  </td>
                  <td className='px-4 py-3 text-sm text-white/70'>
                    {row.motherInfo.name}
                  </td>
                  <td className='px-4 py-3 text-sm text-white/70'>
                    {row.studentInfo.grade}
                  </td>
                  <td className='px-4 py-3'>
                    <span
                      className={`px-2 py-1 text-xs rounded-full ${
                        row.status === 'Approved'
                          ? 'bg-emerald-500/20 text-emerald-300 border border-emerald-500/30'
                          : 'bg-rose-500/20 text-rose-300 border border-rose-500/30'
                      }`}
                    >
                      {row.status}
                    </span>
                  </td>
                  <td className='px-4 py-3'>
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/students/fee-challan/${row._id}`)
                      }}
                      className='px-3 py-1 text-xs bg-gradient-to-r from-amber-500 to-amber-600 text-white rounded-lg hover:from-amber-600 hover:to-amber-700 transition-all'
                    >
                      Show Fee
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className='bg-white/5 px-4 py-3 flex items-center justify-between border-t border-white/10'>
          <div className='text-sm text-white/50'>
            Showing {totalRows ? (currentPage - 1) * entriesPerPage + 1 : 0} to{' '}
            {Math.min(currentPage * entriesPerPage, totalRows)} of {totalRows}{' '}
            entries
          </div>
          <div className='flex gap-1'>
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className='px-3 py-1 text-sm bg-white/10 text-white rounded hover:bg-white/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors'
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`px-3 py-1 text-sm rounded transition-colors ${
                  currentPage === index + 1
                    ? 'bg-indigo-500 text-white'
                    : 'bg-white/10 text-white hover:bg-white/20'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className='px-3 py-1 text-sm bg-white/10 text-white rounded hover:bg-white/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors'
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnrolledDataTable
